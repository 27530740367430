@charset "UTF-8";
.footer {
  text-align: center;
  font-size: 0.9rem;
  font-family: "Montserrat", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}

@media (max-width: 575.98px) {
  .font-sizer{
    font-size: 18px;
    margin-top: 5px;
  }
  .spec {
    margin-top: 150px;
  }
}

@media (min-width: 576px) and (max-width: 1000px) {
  .spec {
    margin-top: 150px;
  }
}
@media (min-width: 1000px) {
  .font-sizer{
    font-size: 22px;
    margin-top: 12px;
  }
  .spec {
    margin-top: 100px;
  }
}

@media (max-width: 1199.98px)  {
    .box-mine {
    font-size: 1em;
    line-height: 1.5em;
    font-weight: 350;
    border-bottom: 2px solid transparent;
    border-top: 0px;
    border-left: 0px;
    border-right: 0px;
    border-image: linear-gradient(0.25turn, rgb(248, 249, 250), black, rgb(248, 249, 250));
    border-image-slice: 1;
  }
  .no-box{
    font-size: 1em;
    line-height: 1.5em;
    font-weight: 350;
    border-bottom: 2px solid transparent;
    border-top: 0px;
    border-left: 0px;
    border-right: 0px;
    border-image: linear-gradient(0.25turn, rgb(248, 249, 250), black, rgb(248, 249, 250));
    border-image-slice: 1;
  }
}

@media (min-width: 1200px) {
  .box-mine {
    margin-right: -12px !important;
    font-size: 1em;
    line-height: 1.5em;
    font-weight: 350;
    border-right: 2px;
    border-left: 0;
    border-top: 0;
    border-bottom: 0;
    border-style: solid;
    border-image: linear-gradient(to bottom, rgb(248, 249, 250), black, rgb(248, 249, 250)) 1 100%;
  }
  .no-box{
    font-size: 1em;
    line-height: 1.5em;
    font-weight: 350;
    border: 0 !important;
  }
}

.card-text {
  text-align: left !important;
}

.spec h1 {
  margin: 30px;
  font-size: 28px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 1.5em;
  padding-bottom: 15px;
  position: relative;
}

.spec h1:before {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  height: 5px;
  width: 55px;
  background-color: #111;
}

.spec h1:after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 2px;
  height: 1px;
  width: 95%;
  max-width: 255px;
  background-color: #333;
}

.contact_view {
  font-size: 13px;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 4px;
  line-height: 3em;
  padding-left: 0.25em;
  color: rgba(0, 0, 0, 4);
  padding-bottom: 10px;
  padding-left: 10px;
}

#navbarLogo {
  height: 60px;
  filter:invert(100%);
}

a {
  color: #7d7d7d;
  text-decoration: none;
}
a:hover {
  color: gray;/*kolor po najechaniu na linki*/
}

a:not([href]):not([class]), a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none;
}

.header-color{
  color: #7d7d7d;
}

.header_contact h1 {
  text-align:center;
  text-transform:uppercase;
  font-size:26px; letter-spacing:1px;
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  grid-template-rows: 16px 0;
  grid-gap: 22px;
}

.header_contact h1:after,.header_contact h1:before {
  content: " ";
  display: block;
  border-bottom: 2px solid #ccc;
  background-color:#f8f8f8;
}

.scroll-to-top {
  position: fixed;
  bottom: 50px;
  right: 0px;
  background-color: #494e53;
  border-radius: 5px;
  color: #fff;
  border: none;
  cursor: pointer;
}

.scroll-to-top:hover {
  background-color: #494e53;
}

.btn-flip{
	opacity: 1;
	outline: 0;
	color: #fff;
	line-height: 40px;
	position: relative;
	text-align: center;
	letter-spacing: 1px;
	display: inline-block;
	text-decoration: none;
	font-family: 'Open Sans';
	text-transform: uppercase;	
	&:hover{
		&:after{
			opacity: 1;
			transform: translateY(0) rotateX(0);
		}
		&:before{
			opacity: 0;
			transform: translateY(50%) rotateX(90deg);
		}
	}
	
	&:after{
		top: 0;
		left: 0;
		opacity: 0;
		width: 100%;
		color: #323237;
		display: block;
		transition: 0.5s;
		position: absolute;
		background: #adadaf;
		content: attr(data-back);
		transform: translateY(-50%) rotateX(90deg);
	}
	
	&:before{
		top: 0;
		left: 0;
		opacity: 1;
		color: #adadaf;
		display: block;
		padding: 0 30px;
		line-height: 40px;
		transition: 0.5s;
		position: relative;
		background: #323237;
		content: attr(data-front);
		transform: translateY(0) rotateX(0);
	}
}

@font-face {
  font-family: 'sans';
  src: url('./font/opensans-variablefont_wdthwght-webfont.woff2') format('woff2'),
       url('./font/opensans-variablefont_wdthwght-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'lora';
  src: url('./font/lora-italic-variablefont_wght-webfont.woff2') format('woff2'),
       url('./font/lora-italic-variablefont_wght-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

.font_sans{
  color: black;
  font-family: 'sans',sans-serif;
  font-size: 15px;
  line-height: 25px;
  font-style: normal;
  font-weight: 300;
}

.font_lora{
  font-family: 'lora',sans-serif;
  font-size: 40px;
  font-weight: 400;
}

.modal-link {
  cursor: pointer;
}

.contact-message {
  background-color: #e50707;
  border-radius: 5px;
  border: 0px;
  transition: background-color 0.5s;
}

.contact-message:hover {
  background-color: white;
  color: #e50707;
}

.navbar-data{
  text-align: center;
}

.no-border{
  border: 0px;
}

.fancy-border{
  border-radius: 10px;
  border: 1px solid gray;
}

.card-aspect {
  aspect-ratio: 10/5;
}

.card-vert-center{
  display: grid;
  align-items: center;
  align-content:center;
}

.color-green{
  color: green;
}

.color-send{
  background-color: #26a4fd;
}

.font-nav{
  font-family: 'sans';
}

@font-face {
  font-family: 'cinzel_regular';
  src: url('./font/cinzeldecorative-regular-webfont.woff2') format('woff2'),
       url('./font/cinzeldecorative-regular-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'cinzel_bold';
  src: url('./font/cinzeldecorative-bold-webfont.woff2') format('woff2'),
       url('./font/cinzeldecorative-bold-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;

}

.font-cinreg{
  font-family:'cinzel_regular';
}

.font-cinbol{
  font-family:'cinzel_bold';
}

@media screen and (min-width: 1000px){
.home-title div {  
  letter-spacing:1px;
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  grid-template-rows: 22px 0;
  grid-gap: 22px;
}

.home-title div:after,.home-title div:before {
  content: " ";
  display: block;
  border-bottom: 2px solid #878787; 
}
}

.font-con{
  font-family: 'lora',sans-serif;
}

.box-contact {
  background-color: rgba(#000, 0.5);  
  padding: 5px;
  border: 2px solid #b78846;
  &:before, &:after {
    content: "•";
    position: absolute;
    width: 14px;
    height: 14px;
    color: #b78846;
    border: 2px solid #b78846;
    line-height: 12px;
    top: 5px;
    text-align: center;
  }
  &:before {
    left: 5px;
  }
  &:after {
    right: 5px;
  }
  .box-contact-inner {
    position: relative;
    border: 2px solid #b78846;
    padding: 25px;
    &:before, &:after {
      content: "•";
      position: absolute;
      width: 14px;
      height: 14px;
      font-size: 14px;
      color: #b78846;
      border: 2px solid #b78846;
      line-height: 12px;
      bottom: -2px;
      text-align: center;
    }
    &:before {
      left: -2px;
    }
    &:after {
      right: -2px;
    }
  }
}

.fade-out-map {
  opacity: 1;
  visibility: visible;
  transition: opacity 1s ease-in-out, visibility 1s ease-in-out;
}

.fade-out-map.hidden {
  opacity: 0;
  visibility: hidden;
}

.center-toast {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index:9999;
}

.toast-header {
  text-align: center;
}

.toast-header .btn-close {
  display: none;
}

.fade-scale {
  transform: scale(0);
  opacity: 0;
  -webkit-transition: all .20s linear;
  -o-transition: all .20s linear;
  transition: all .20s linear;
}

.fade-scale.in {
  opacity: 1;
  transform: scale(1);
  backdrop-filter: blur(1px);
}


.modal-content {
  border-radius: 20px;
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.2);
}

.modal-header {
  background-color: #494949;
  color: white;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
}

.modal-body {
  padding: 20px;
}

.form-group {
  margin-bottom: 20px;
}

.form-control {
  border-radius: 10px;
  border: 2px solid #494949;
  padding: 10px;
}

.btn.color-send {
  background-color: #494949;
  color: white;
  border: none;
  border-radius: 10px;
  padding: 10px 20px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.btn.color-send:hover {
  background-color: gray;
}
